// @ts-nocheck
import React from 'react';

import { useAssistantRuntime } from '@assistant-ui/react';
import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  styled,
} from '@mui/material';

const SidebarContainer = styled(Box)(({ theme, isCollapsed }) => ({
  height: '100%',
  width: isCollapsed ? 30 : 250,
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.shortest,
  }),
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(2),
}));

const NewChatButton = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.grey[300],
  },
}));

const ChatListItem = styled(ListItem)(({ theme, selected }) => ({
  padding: theme.spacing(1),
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey[300],
  },
  ...(selected && {
    backgroundColor: theme.palette.grey[300],
  }),
}));

const ChatSidebar = ({
  onNewChat,
  threads = [],
  currentThreadId,
  onThreadSelect,
  onDeleteThread,
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const runtime = useAssistantRuntime();

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleThreadSelect = async (threadId) => {
    try {
      if (threadId === currentThreadId) return;

      await runtime.switchToThread(threadId);
      onThreadSelect(threadId);
    } catch (error) {
      console.error('Error switching thread:', error);
    }
  };

  const handleDeleteThread = async (e, threadId) => {
    try {
      e.stopPropagation();

      if (threads.length <= 1) {
        console.warn('Cannot delete the last thread');
        return;
      }

      if (threadId === currentThreadId) {
        const nextThread = threads.find((t) => t.id !== threadId);
        if (nextThread) {
          await runtime.switchToThread(nextThread.id);
        }
      }

      await onDeleteThread(threadId);
    } catch (error) {
      console.error('Error deleting thread:', error);
    }
  };

  const handleNewChat = async () => {
    try {
      await onNewChat();
    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };

  return (
    <SidebarContainer isCollapsed={isCollapsed}>
      <IconButton
        onClick={toggleSidebar}
        sx={{
          position: 'absolute',
          right: -20,
          top: 20,
          bgcolor: 'grey.300',
          '&:hover': {
            bgcolor: 'grey.400',
          },
          zIndex: 1,
        }}
      >
        {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>

      <List>
        <NewChatButton onClick={handleNewChat} component="div">
          <ListItemIcon sx={{ minWidth: 40, color: 'common.info' }}>
            <AddIcon />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="New Chat" />}
        </NewChatButton>

        {threads.map((thread) => (
          <ChatListItem
            key={thread.id}
            selected={currentThreadId === thread.id}
            onClick={() => handleThreadSelect(thread.id)}
          >
            {!isCollapsed && (
              <>
                <ListItemText
                  primary={thread.title || 'New Conversation'}
                  sx={{
                    '& .MuiListItemText-primary': {
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    },
                  }}
                />
                {threads.length > 1 && (
                  <IconButton
                    size="small"
                    onClick={(e) => handleDeleteThread(e, thread.id)}
                    sx={{
                      color: 'grey.400',
                      '&:hover': {
                        color: 'error.main',
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </>
            )}
          </ChatListItem>
        ))}
      </List>
    </SidebarContainer>
  );
};

export default ChatSidebar;
