import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { Box, CircularProgress, Tab, Tabs, Tooltip, Typography } from '@mui/material';

import CustomBackdrop from '../../components/CustomBackdrop/CustomBackdrop';
import {
  CustomerSegmentsDocument,
  useAddSegmentCustomerMutation,
  useCustomerSegmentsQuery,
  useDeleteSegmentMutation,
} from '../../gql/generated/graphql';

import AddNewSegment from './components/AddNewSegment';
import AdminTab from './components/AdminTab';
import TabSegmentList from './components/TabSegmentList';

import styles from './styles';

const CustomerSegmentPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(0);
  const [newSegmentData, setNewSegmentData] = useState({
    name: '',
    description: '',
    keyword1: '',
  });

  const companyParamsId = searchParams.get('companyId');

  const { data, loading } = useCustomerSegmentsQuery({
    variables: { companyId: parseInt(companyParamsId) },
    skip: !companyParamsId,
  });
  const [addSegment, { loading: addSegmentLoading }] = useAddSegmentCustomerMutation();
  const [deleteSegment, { loading: deleteSegmentLoading }] = useDeleteSegmentMutation();

  const segments = data?.customerSegments || [];
  const totalTabs = segments.length + 2;

  const handleTabChange = (_, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleNewSegmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSegmentData({
      ...newSegmentData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSave = async () => {
    try {
      await addSegment({
        variables: {
          input: {
            bidGroupCompanyId: parseInt(companyParamsId),
            segmentName: newSegmentData.name,
            segmentDescription: newSegmentData.description,
            keyword1: newSegmentData.keyword1,
          },
        },
        refetchQueries: [
          {
            query: CustomerSegmentsDocument,
            variables: { companyId: parseInt(companyParamsId) },
          },
        ],
      });
      setNewSegmentData({ name: '', description: '', keyword1: '' });
      setActiveTab(totalTabs - 1);
    } catch (error) {
      console.error('Error adding segment:', error);
    }
  };

  const handleDeleteSegment = (id: number) => {
    try {
      deleteSegment({
        variables: {
          segmentId: id,
        },
        refetchQueries: [
          {
            query: CustomerSegmentsDocument,
            variables: { companyId: parseInt(companyParamsId) },
          },
        ],
      });
    } catch (error) {
      console.error('Error deleting segment:', error);
    } finally {
      setActiveTab(0);
    }
  };

  if (!companyParamsId) {
    return (
      <Typography color="error" variant="h6">
        {t('customerSegmentPage.missingCompanyId')}
      </Typography>
    );
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={200}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box width="100%">
      <Box sx={styles.container}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="customer segments tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={styles.tabs}
        >
          <Tab label="Admin" id="segment-tab-0" aria-controls="segment-tabpanel-0" />
          {segments.map((segment, index) => (
            <Tab
              key={segment.id}
              label={segment.segmentName}
              id={`segment-tab-${index + 1}`}
              aria-controls={`segment-tabpanel-${index + 1}`}
            />
          ))}
          <Tooltip title={t('customerSegmentPage.addNewSegmentTooltip')}>
            <Tab
              icon={<AddIcon />}
              aria-label="add segment"
              id={`segment-tab-${totalTabs - 1}`}
              aria-controls={`segment-tabpanel-${totalTabs - 1}`}
            />
          </Tooltip>
        </Tabs>
      </Box>

      <AdminTab activeTab={activeTab} companyId={companyParamsId} />
      <TabSegmentList
        segments={segments}
        activeTab={activeTab}
        companyId={companyParamsId}
        onDeleteSegment={handleDeleteSegment}
      />
      <AddNewSegment
        activeTab={activeTab}
        totalTabs={totalTabs}
        newSegmentData={newSegmentData}
        handleNewSegmentChange={handleNewSegmentChange}
        handleSave={handleSave}
      />
      <CustomBackdrop open={addSegmentLoading || deleteSegmentLoading} />
    </Box>
  );
};

export default CustomerSegmentPage;
