// @ts-nocheck
import '@assistant-ui/react/styles/index.css';
import '@assistant-ui/react/styles/modal.css';

import { useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import {
  Composer,
  Thread,
  ThreadWelcome,
  useAssistantRuntime,
  useThreadList,
} from '@assistant-ui/react';
import { useContentPartText } from '@assistant-ui/react';
import { useLangGraphRuntime } from '@assistant-ui/react-langgraph';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { createThread, getThreadState, sendMessage } from '../../lib/chatApi';

import ChatSidebar from './ChatSidebar';

const StyledThreadRoot = styled(Thread.Root)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(2),
  height: '100%',
}));

const config = {
  assistantMessage: { components: { Text: CustomMarkdownTextPart } },
  assistantAvatar: {
    src: 'logo.png',
    alt: 'Assistant avatar',
    fallback: 'Cortex',
  },
};

function CustomLink({ href, children }: { href?: string; children: React.ReactNode }) {
  const style = { textDecoration: 'underline', fontWeight: 'bold' };

  if (href && href.startsWith('company_id=')) {
    const companyId = href.split('=')[1];
    return (
      <a href={`/evaluation/dfm?companyId=${companyId}`} rel="noopener noreferrer" style={style}>
        {children}
      </a>
    );
  } else {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" style={style}>
        {children}
      </a>
    );
  }
}

function CustomMarkdownTextPart() {
  const { text } = useContentPartText();

  return (
    <ReactMarkdown
      components={{
        a: ({ href, children }) => <CustomLink href={href}>{children}</CustomLink>,
      }}
    >
      {text}
    </ReactMarkdown>
  );
}

export function ChatbotAssistant() {
  const runtime = useAssistantRuntime();
  const threadList = useThreadList();
  const storedThreadId =
    typeof window !== 'undefined' ? sessionStorage.getItem('threadId') : undefined;
  const threadIdRef = useRef<string | undefined>(storedThreadId || undefined);

  const threads = threadList.threads.map((thread) => ({
    id: thread.id,
    title: thread.title || 'New Conversation',
    lastMessage: thread.lastMessage?.content || '',
    createdAt: thread.createdAt,
    updatedAt: thread.updatedAt,
  }));

  const handleNewChat = async () => {
    const newThread = await createThread();
    console.log('newThread', newThread);
    threadIdRef.current = newThread.id;
    if (typeof window !== 'undefined') {
      sessionStorage.setItem('threadId', newThread.id);
    }
    runtime.switchToNewThread();
  };

  const handleDeleteThread = async (threadId: string) => {
    await threadList.deleteThread(threadId);
    if (threadId === threadIdRef.current) {
      handleNewChat();
    }
  };

  return (
    <Box display="flex" height="90vh" gap={2}>
      <ChatSidebar
        threads={threads}
        currentThreadId={threadIdRef.current}
        onNewChat={handleNewChat}
        onThreadSelect={(threadId) => runtime.switchToThread(threadId)}
        onDeleteThread={handleDeleteThread}
      />

      <Box flexGrow={1}>
        <StyledThreadRoot config={config}>
          <Thread.Viewport>
            <ThreadWelcome />
            <Thread.Messages />
            <Thread.ViewportFooter>
              <Thread.ScrollToBottom />
              <Composer />
            </Thread.ViewportFooter>
          </Thread.Viewport>
        </StyledThreadRoot>
      </Box>
    </Box>
  );
}
