export const paths = {
  main: '/',
  search: '/search',
  validation: '/validation',
  crawlerInbox: '/crawler-inbox',
  crawlerOps: '/crawler-ops',
  benchmarking: '/benchmarking',
  publicAnalysis: '/public-analysis',
  notifications: '/notifications',
  settings: '/settings',
  evaluation: {
    evaluation: '/evaluation',
    customerSegment: '/customer-segment',
    dfm: '/evaluation/dfm',
    companyFiles: '/evaluation/company-files',
    marketSegments: '/evaluation/market-segments',
    bbLists: '/evaluation/bb-lists',
    outreach: '/evaluation/outreach',
  },
  research: {
    softwareCategories: 'software-categories',
    customerIndustries: 'customer-industries',
    matrixSearch: 'matrix-search',
  },
};
