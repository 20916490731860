import { useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

const AddShareholderDialog = ({ open, onClose, onAdd }) => {
  const [formData, setFormData] = useState({
    name: '',
    typeOfInvestor: '',
    yearBornOrFounded: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    onAdd(formData);
    setFormData({ name: '', typeOfInvestor: '', yearBornOrFounded: '' });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Add Shareholder</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: '100%',
            marginTop: 2,
          }}
        >
          <TextField
            label="Name"
            name="name"
            value={formData?.name}
            onChange={handleChange}
            fullWidth
            required
            size="small"
          />
          <TextField
            label="Type of Investor"
            name="typeOfInvestor"
            value={formData?.typeOfInvestor}
            onChange={handleChange}
            fullWidth
            size="small"
          />
          <TextField
            label="Year Born or Founded"
            name="yearBornOrFounded"
            value={formData?.yearBornOrFounded}
            onChange={handleChange}
            type="number"
            fullWidth
            size="small"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          disabled={!formData.name}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddShareholderDialog;
