import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';

import { useResegmentCustomersMutation } from '../../../gql/generated/graphql';

import styles from './styles/adminTabStyles';
import TabPanel from './TabPanel';

type Props = {
  activeTab: number;
  companyId: string;
};

const AdminTab = ({ activeTab, companyId }: Props) => {
  const { t } = useTranslation();
  const [resegmentCustomers, { loading }] = useResegmentCustomersMutation({
    variables: { companyId: parseInt(companyId) },
  });

  const handleResegment = () => {
    if (!companyId) return;
    resegmentCustomers();
  };

  return (
    <TabPanel value={activeTab} index={0}>
      <Typography variant="h6">{t('customerSegmentPage.adminTabTitle')}</Typography>
      <Box mt={2} display="flex" flexDirection="column" gap={1}>
        <Typography>{t('customerSegmentPage.adminTabResegmentTitle')}</Typography>
        <Button
          disabled={!companyId}
          onClick={handleResegment}
          sx={styles.button}
          variant="contained"
        >
          {loading
            ? t('customerSegmentPage.adminTabResegmentButtonLoading')
            : t('customerSegmentPage.adminTabResegmentButton')}
        </Button>
      </Box>
    </TabPanel>
  );
};

export default AdminTab;
