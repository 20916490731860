import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import CustomBackdrop from '../../../../components/CustomBackdrop/CustomBackdrop';
import {
  GetAllShareholdersDocument,
  GetAllShareholdingsDocument,
  useCreateUpdateGenericShareholderMutation,
  useCreateUpdateGenericShareholdingMutation,
  useDeleteGenericShareholderMutation,
  useGetAllShareholdersQuery,
  useGetAllShareholdingsQuery,
} from '../../../../gql/generated/graphql';

import AddShareholderDialog from './components/AddShareholderDialog';
import AddShareholding from './components/AddShareholding';

type Props = {
  companyId: number;
};

const ValidateShareholdingManagement = ({ companyId }: Props) => {
  const { t } = useTranslation();
  const [shareholderId, setShareholderId] = useState<number | null>(null);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isEditting, setIsEditting] = useState(false);
  const [initialData, setInitialData] = useState(null);

  const { data: shareholdingsData, loading: loadingShareholders } = useGetAllShareholdingsQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const { data: sharholdersData, refetch: refetchShareholders } = useGetAllShareholdersQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const shareholdingRows =
    shareholdingsData?.getCompany?.edges?.[0]?.node?.genericShareholdings?.edges?.map(
      (shareholder) => ({
        genericCompanyId: shareholder?.node?.genericCompanyId,
        genericShareholderId: shareholder?.node?.genericCompanyId,
        holding: shareholder?.node?.holding,
        holdingCount: shareholder?.node?.holdingCount,
        ...shareholder?.node?.genericShareholder,
      }),
    );
  const shareholders = sharholdersData?.getCompany?.edges?.[0]?.node?.shareholders?.edges?.map(
    (node) => node?.node,
  );

  const [deleteShareholder, { loading: deleteShareholderLoading }] =
    useDeleteGenericShareholderMutation();
  const [createUpdateGenericShareholder, { loading: createUpdateGenericShareholderLoading }] =
    useCreateUpdateGenericShareholderMutation();
  const [createUpdateGenericShareholding, { loading: createUpdateGenericShareholdingLoading }] =
    useCreateUpdateGenericShareholdingMutation();

  const shareholdingsColumns = [
    {
      field: 'name',
      width: 300,
      headerName: t('validation.validateShareholdingManagement.shareholdingsColumnsNameLabel'),
    },
    {
      field: 'holding',
      width: 100,
      headerName: t('validation.validateShareholdingManagement.shareholdingsColumnsHoldingLabel'),
    },
    {
      field: 'holdingCount',
      width: 100,
      headerName: t(
        'validation.validateShareholdingManagement.shareholdingsColumnsHoldingCountLabel',
      ),
    },
    {
      field: 'yearBornOrFounded',
      width: 150,
      headerName: 'Year Born/Founded',
    },
    {
      field: 'isPe',
      width: 150,
      headerName: t('validation.validateShareholdingManagement.shareholdingsColumnsIsPeLabel'),
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
          {params?.formattedValue ? <DoneIcon fontSize="small" /> : <CloseIcon fontSize="small" />}
        </Box>
      ),
    },
    {
      field: 'isTradeBuyer',
      width: 150,
      headerName: t(
        'validation.validateShareholdingManagement.shareholdingsColumnsIsTradeBuyerLabel',
      ),
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
          {params?.formattedValue ? <DoneIcon fontSize="small" /> : <CloseIcon fontSize="small" />}
        </Box>
      ),
    },
    {
      field: 'isNaturalPerson',
      width: 150,
      headerName: t(
        'validation.validateShareholdingManagement.shareholdingsColumnsIsNaturalPersonLabel',
      ),
      renderCell: (params) => (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
          {params?.formattedValue ? <DoneIcon fontSize="small" /> : <CloseIcon fontSize="small" />}
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      editable: false,
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <>
          {/* TODO: disabled for now */}
          {/* <IconButton size="small" onClick={() => handleEdit(params.row)}> */}
          <IconButton size="small" onClick={() => {}}>
            <Tooltip title={'Edit'}>
              <EditOutlinedIcon fontSize="small" />
            </Tooltip>
          </IconButton>
          {/* <IconButton size="small" onClick={() => handleDelete(params.row.dbId)}> */}
          <IconButton size="small" onClick={() => {}}>
            <Tooltip title={'Delete'}>
              <DeleteOutlineIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        </>
      ),
    },
  ];

  const handleOpenDialog = () => {
    setIsOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
    setInitialData(null);
    setIsEditting(false);
  };

  const handleAddShareholder = async (formData: {
    name: string;
    typeOfInvestor?: string;
    yearBornOrFounded?: number;
  }) => {
    try {
      const variables: Record<string, any> = {
        // TODO: uncomment later
        // genericCompanyId: companyId,
        genericCompanyId: 101,
        name: formData.name,
      };

      if (formData.typeOfInvestor) {
        variables.typeOfInvestor = formData.typeOfInvestor;
      }

      if (formData.yearBornOrFounded) {
        variables.yearBornOrFounded = formData.yearBornOrFounded;
      }
      const result = await createUpdateGenericShareholder({
        variables,
      });

      if (result?.data?.createUpdateGenericShareholder?.name) {
        await refetchShareholders();
      }
    } finally {
      handleCloseDialog();
    }
  };

  const handleAddShareholding = async (holding: number, holdingCount: number) => {
    if (!shareholderId) return;

    try {
      const result = await createUpdateGenericShareholding({
        variables: {
          genericCompanyId: companyId,
          genericShareholderId: shareholderId,
          holding,
          holdingCount,
        },
        refetchQueries: [
          {
            query: GetAllShareholdingsDocument,
            variables: { companyId },
          },
        ],
      });
      if (result?.data?.createUpdateGenericShareholding) {
        setShareholderId(null);
      }
    } catch {}
  };

  const handleEdit = async (row) => {
    setIsEditting(true);
    setInitialData({
      name: row.name,
      typeOfInvestor: row.typeOfInvestor,
      yearBornOrFounded: row.yearBornOrFounded,
      dbId: row.dbId,
    });
    handleOpenDialog();
  };

  const handleEditShareholder = async (formData: {
    name: string;
    typeOfInvestor: string;
    yearBornOrFounded: number;
    dbId: number;
  }) => {
    try {
      await createUpdateGenericShareholder({
        variables: {
          genericCompanyId: companyId,
          genericShareholderId: formData.dbId,
          name: formData?.name,
          typeOfInvestor: formData?.typeOfInvestor,
          yearBornOrFounded: formData?.yearBornOrFounded,
        },
        refetchQueries: [
          {
            query: GetAllShareholdersDocument,
            variables: { companyId },
          },
        ],
      });
    } finally {
      handleCloseDialog();
    }
  };

  const handleDelete = async (genericShareholderId: number) => {
    await deleteShareholder({
      variables: { genericShareholderId },
    });
  };

  const showBackdrop =
    deleteShareholderLoading ||
    createUpdateGenericShareholdingLoading ||
    createUpdateGenericShareholderLoading;

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <DataGrid
        disableRowSelectionOnClick
        rows={shareholdingRows || []}
        loading={loadingShareholders}
        columns={shareholdingsColumns}
        rowHeight={35}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pageSizeOptions={[10, 25, 50, 80]}
        getRowId={(row) => row?.dbId}
      />
      <Button
        sx={{ mt: 2, alignSelf: 'flex-start' }}
        variant="contained"
        color="primary"
        onClick={handleOpenDialog}
      >
        Add Shareholder
      </Button>
      <AddShareholderDialog
        open={isOpenDialog}
        onClose={handleCloseDialog}
        onAdd={handleAddShareholder}
      />
      <AddShareholding
        shareholders={shareholders}
        shareholderId={shareholderId}
        onSubmit={handleAddShareholding}
        onSetShareholderId={setShareholderId}
      />
      <CustomBackdrop open={showBackdrop} />
    </Box>
  );
};

export default ValidateShareholdingManagement;
