import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';

import {
  CustomerSegmentsDocument,
  CustomerSegmentsQuery,
  useUpdateSegmentForCustomerMutation,
} from '../../../gql/generated/graphql';

import styles from './styles/segmentStyles';

type Props = {
  segment: CustomerSegmentsQuery['customerSegments'][0];
  companyId: string;
};

const Segment = ({ segment, companyId }: Props) => {
  const { t } = useTranslation();

  const [updateSegment, { loading }] = useUpdateSegmentForCustomerMutation();

  const [formData, setFormData] = useState({
    segmentName: segment.segmentName,
    segmentDescription: segment.segmentDescription,
    keyword1: segment.keyword1,
  });

  const handleInputChange =
    (field: keyof typeof formData) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormData((prev) => ({
        ...prev,
        [field]: event.target.value,
      }));
    };

  const handleUpdate = () => {
    const { segmentName, segmentDescription, keyword1 } = formData;

    updateSegment({
      variables: {
        segmentId: segment.id,
        input: {
          segmentName,
          segmentDescription,
          keyword1,
          bidGroupCompanyId: segment.bidGroupCompanyId,
        },
      },
      refetchQueries: [
        {
          query: CustomerSegmentsDocument,
          variables: { companyId: parseInt(companyId) },
        },
      ],
    });
  };

  const disabledButton =
    !formData.segmentName || !formData.segmentDescription || !formData.keyword1;

  return (
    <Box display="flex" flexDirection="column" mt={2} gap={2}>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography component="label" sx={styles.inputTitle}>
          {t('customerSegmentPage.segmentName')}:
        </Typography>
        <TextField
          size="small"
          value={formData.segmentName}
          onChange={handleInputChange('segmentName')}
          fullWidth
          required
        />
      </Box>

      <Box display="flex" alignItems="center" gap={1}>
        <Typography component="label" sx={styles.inputTitle}>
          {t('customerSegmentPage.segmentName')}:
        </Typography>
        <TextField
          size="small"
          value={formData.segmentDescription}
          onChange={handleInputChange('segmentDescription')}
          fullWidth
          required
        />
      </Box>

      <Box display="flex" alignItems="center" gap={1}>
        <Typography component="label" sx={styles.inputTitle}>
          {t('customerSegmentPage.segmentKeyword')}:
        </Typography>
        <TextField
          size="small"
          value={formData.keyword1}
          onChange={handleInputChange('keyword1')}
          fullWidth
          required
        />
      </Box>

      <Button
        disabled={disabledButton}
        variant="contained"
        color="primary"
        onClick={handleUpdate}
        sx={styles.button}
      >
        {loading
          ? t('customerSegmentPage.segmentUpdateButtonLoading')
          : t('customerSegmentPage.segmentUpdateButton')}
      </Button>
    </Box>
  );
};

export default Segment;
