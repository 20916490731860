import { useRef } from 'react';

import { AssistantRuntimeProvider } from '@assistant-ui/react';
import { useLangGraphRuntime } from '@assistant-ui/react-langgraph';

import { createThread, getThreadState, sendMessage } from '../lib/chatApi';

export function ChatbotAssistantRuntimeProvider({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const threadIdRef = useRef<string | undefined>();
  const runtime = useLangGraphRuntime({
    threadId: threadIdRef.current,
    stream: async (messages) => {
      if (!threadIdRef.current) {
        const { thread_id } = await createThread();
        threadIdRef.current = thread_id;
        if (typeof window !== 'undefined') {
          sessionStorage.setItem('threadId', thread_id);
        }
      }
      const threadId = threadIdRef.current;
      return sendMessage({ threadId, messages });
    },
    onSwitchToNewThread: async () => {
      const { thread_id } = await createThread();
      threadIdRef.current = thread_id;
      if (typeof window !== 'undefined') {
        sessionStorage.setItem('threadId', thread_id);
      }
    },
    onSwitchToThread: async (threadId) => {
      const state = await getThreadState(threadId);
      threadIdRef.current = threadId;
      if (typeof window !== 'undefined') {
        sessionStorage.setItem('threadId', threadId);
      }
      return { messages: state.values.messages };
    },
  });

  return <AssistantRuntimeProvider runtime={runtime}>{children}</AssistantRuntimeProvider>;
}
