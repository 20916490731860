import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import RootLayout from '../layouts/RootLayot';
import {
  BenchmarkingOverview,
  BuyBuild,
  CompanyFilesPage,
  CrawlerInbox,
  CustomerIndustriesPage,
  CustomerSegmentPage,
  DFMPage,
  EvaluationPage,
  Home,
  MatrixSearchPage,
  NotFound,
  NotificationsPage,
  SearchPage,
  SettingsPage,
  SoftwareCategoriesPage,
  ValidationWrapper,
} from '../pages';
import MarketSegmentPage from '../pages/MarketSegments/MarketSegmentPage';

import { paths } from './paths';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<RootLayout />}>
        <Route index element={<Home />} />
        <Route path={paths.search} element={<SearchPage />} />
        <Route path={paths.validation} element={<ValidationWrapper />} />
        <Route path={paths.evaluation.evaluation} element={<EvaluationPage />} />
        <Route path={paths.evaluation.dfm} element={<DFMPage />} />
        <Route path={paths.evaluation.companyFiles} element={<CompanyFilesPage />} />
        <Route path={paths.evaluation.bbLists} element={<BuyBuild />} />
        <Route path={paths.evaluation.outreach} element={<div>Outreach Page</div>} />
        <Route path={paths.crawlerInbox} element={<CrawlerInbox />} />
        <Route path={paths.benchmarking} element={<BenchmarkingOverview />} />
        <Route path={paths.research.softwareCategories} element={<SoftwareCategoriesPage />} />
        <Route path={paths.research.customerIndustries} element={<CustomerIndustriesPage />} />
        <Route path={paths.research.matrixSearch} element={<MatrixSearchPage />} />
        <Route path={paths.notifications} element={<NotificationsPage />} />
        <Route path={paths.crawlerOps} element={<div>Crawler OPs Page</div>} />
        <Route path={paths.evaluation.customerSegment} element={<CustomerSegmentPage />} />
        <Route path={paths.settings} element={<SettingsPage />} />
        <Route path={paths.publicAnalysis} element={<div>Public Analysis Page</div>} />
        <Route
          path={paths.evaluation.marketSegments}
          element={<MarketSegmentPage companyId={''} />}
        />
        <Route path="/*" element={<NotFound />} />
      </Route>
    </>,
  ),
);

export default router;
