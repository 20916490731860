import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import LanguageIcon from '@mui/icons-material/Language';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useDebouncedCallback } from 'use-debounce';

import CustomBackdrop from '../../../../components/CustomBackdrop/CustomBackdrop';
import SicCodeOpenAi from '../../../../components/SicCodeOpenAi/SicCodeOpenAi';
import { SIC_CODES_LIST } from '../../../../contants';
import {
  SicCodeEdge,
  useAddIndustryMutation,
  useDeleteCustomerReferenceMutation,
  useGetAllSicCodesLazyQuery,
  useGetCompanyQuery,
  useGetCustomerReferencesQuery,
  useMarkCustomerReferenceObsoleteMutation,
  useMarkProductAsHorizontalMutation,
} from '../../../../gql/generated/graphql';
import { getFirstNode, getNodes } from '../../../../gql/helpers';
import { ensureProtocol, openCorrectedLink } from '../../../../helpers/helpers';
import CustomLink from '../../../DFMPage/components/CustomLink/CustomLink';
import SicCodesSearch from '../SicCodesSearch/SicCodesSearch';

import { validateCustomerStyles } from './styles';

type Props = {
  companyId: number;
};

const ValidateCustomers = ({ companyId }: Props) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);
  const [sicCode, setSicCode] = useState(null);
  const [mentionedAtURL, setMentionedAtURL] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [openAISicCode, setOpenAISicCode] = useState<string>('');

  const {
    data: customerReferences,
    loading: customerReferencesLoading,
    refetch: refetchCustomerReferences,
  } = useGetCustomerReferencesQuery({ variables: { companyId } });
  const [getAllSicCodes, { data: allSicCodes, loading: allSicCodesLoading }] =
    useGetAllSicCodesLazyQuery();
  const { data: company } = useGetCompanyQuery({ variables: { companyId } });
  const [markCustomerReferenceObsolete, { loading: markCustomerReferenceObsoleteLoading }] =
    useMarkCustomerReferenceObsoleteMutation();
  const [deleteCustomerReference, { loading: deleteCustomerReferenceLoading }] =
    useDeleteCustomerReferenceMutation();
  const [addIndustry, { loading: addIndustryLoading }] = useAddIndustryMutation();
  const [markAsHorizontal, { loading: markAsHorizontalLoading }] =
    useMarkProductAsHorizontalMutation();

  useEffect(() => {
    getAllSicCodes();
  }, []);

  useEffect(() => {
    if (openAISicCode) {
      handleInputChange(null, openAISicCode, '');
    }
  }, [openAISicCode]);

  const debouncedSearch = useDebouncedCallback((value: string) => {
    getAllSicCodes({ variables: { searchTerm: value } });
  }, 500);

  const customerReferencesNodes = getNodes(customerReferences?.getCustomerReferences);
  const companyNode = getFirstNode(company?.getCompany);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleSetOpenAISicCode = (code: string) => {
    setOpenAISicCode(code);
  };

  const productCategoryColumns = [
    {
      field: 'IAB1',
      headerName: t('validation.validateCustomers.category'),
      width: 150,
    },
    {
      field: 'shortName',
      headerName: t('validation.validateCustomers.shortName'),
      width: 150,
    },
    {
      field: 'crawlUrl',
      headerName: t('validation.validateCustomers.url'),
      width: 150,
    },
    {
      field: 'genericCustomer',
      headerName: t('validation.validateCustomers.isGeneric'),
      renderCell: (params) => {
        return params?.formattedValue ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <DoneIcon fontSize="small" />
          </Box>
        ) : null;
      },
    },
    {
      field: 'ISDd',
      headerName: t('validation.validateCustomers.isDD'),
      renderCell: (params) => {
        return params?.formattedValue ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <DoneIcon fontSize="small" />
          </Box>
        ) : null;
      },
    },
    {
      field: 'sicCode',
      headerName: t('validation.validateCustomers.sic'),
    },
    {
      field: 'IAB2',
      headerName: t('validation.validateCustomers.category2'),
      width: 100,
    },
    {
      field: 'IAB3',
      headerName: t('validation.validateCustomers.sub'),
      width: 100,
    },
    {
      field: 'actions',
      headerName: '',
      editable: false,
      sortable: false,
      width: 200,
      renderCell: (params) => (
        <>
          <IconButton size="small" onClick={() => handleSeeFinding(params.row)}>
            <Tooltip title={t('validation.validateCustomers.rowIconTooltipSeeFinding')}>
              <VisibilityIcon fontSize="small" />
            </Tooltip>
          </IconButton>
          <IconButton size="small" onClick={() => handleSeeCustomer(params.row)}>
            <Tooltip title={t('validation.validateCustomers.rowIconTooltipVisitCustomer')}>
              <LanguageIcon fontSize="small" />
            </Tooltip>
          </IconButton>
          <IconButton size="small" onClick={() => handleWrong(params.row)}>
            <Tooltip title={t('validation.validateCustomers.rowIconTooltipWrong')}>
              <CancelPresentationIcon fontSize="small" />
            </Tooltip>
          </IconButton>
          <IconButton size="small" onClick={() => handleDelete(params.row)}>
            <Tooltip title={t('validation.validateCustomers.rowIconTooltipDelete')}>
              <ClearIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        </>
      ),
    },
  ];

  const handleSeeFinding = (row) => {
    openCorrectedLink(row?.findingUrl);
  };

  const handleSeeCustomer = (row) => {
    const url = ensureProtocol(row?.crawlUrl);
    window.open(url, '_blank');
  };

  const handleWrong = async (row) => {
    const result = await markCustomerReferenceObsolete({
      variables: { customerReferenceId: row?.dbId },
    });
    if (result?.data?.markCustomerReferenceObsolete?.success) {
      await refetchCustomerReferences();
    }
  };

  const handleDelete = async (row) => {
    const result = await deleteCustomerReference({
      variables: { customerReferenceId: row?.dbId },
    });
    if (result?.data?.deleteCustomerReference?.success) {
      await refetchCustomerReferences();
    }
  };

  const handleSubmit = async () => {
    const reference = companyNode?.uniqueDomain + '/' + mentionedAtURL;
    const result = await addIndustry({
      variables: {
        companyId,
        sicCode,
        referencedAt: reference,
      },
    });
    if (result?.data?.addIndustry?.sicCode) {
      setSicCode(null);
      setMentionedAtURL('');
      setSearchTerm('');
      await refetchCustomerReferences();
    }
  };

  const handleMarkProductAsHorizontal = async () => {
    const result = await markAsHorizontal({ variables: { softwareCompanyId: companyId } });

    if (result?.data?.markProductAsHorizontal?.success) {
      await refetchCustomerReferences();
    }
  };

  const handleInputChange = (_, value: string, reason: string) => {
    if (reason === 'clear' || !value) {
      handleReset();
      return;
    }

    setSearchTerm(value);
    if (value) {
      debouncedSearch(value);
    }
  };

  const handleReset = () => {
    setSearchTerm('');
    setSicCode(null);
    getAllSicCodes();
  };

  const handleOptionSelect = (_: SyntheticEvent, value: SicCodeEdge) => {
    if (!value?.node?.id) return;
    setSicCode(value?.node?.sicCodeStr ?? null);
  };

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <DataGrid
        loading={customerReferencesLoading}
        disableRowSelectionOnClick
        rows={customerReferencesNodes || []}
        columns={productCategoryColumns}
        rowHeight={35}
        // TODO: all rows must have unique dbId's or id's, backend currently returns null, remove math.random
        getRowId={(row) => row?.dbId || Math.random()}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        pageSizeOptions={[10, 25, 50, 80]}
      />
      <Box mt={2}>
        <Typography>{t('validation.validateCustomers.editCustomersBox')}</Typography>
        <Box sx={validateCustomerStyles.tagsBox}>
          <Tabs value={tab} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab label={t('validation.validateCustomers.addIndustriesTabLabel')} />
            <Tab disabled label={t('validation.validateCustomers.addCustomersTabLabel')} />
          </Tabs>
        </Box>
        {tab === 0 && (
          <Box p={3}>
            <Stack>
              <CustomLink sx={validateCustomerStyles.customLinkSicCodes} to={SIC_CODES_LIST}>
                {t('validation.validateCustomers.tabSicCodesLink')}
              </CustomLink>
              <FormControl fullWidth size="small">
                <SicCodesSearch
                  options={allSicCodes?.getAllSicCodes?.edges || []}
                  loading={allSicCodesLoading}
                  searchTerm={searchTerm}
                  onInputChange={handleInputChange}
                  onOptionSelect={handleOptionSelect}
                />
                <TextField
                  sx={validateCustomerStyles.addIndustryTabUrlTextField}
                  label={t('validation.validateCustomers.mentionedAtUrlTextfieldLabel')}
                  id="mentioned_at_url"
                  fullWidth
                  value={mentionedAtURL}
                  onChange={(e) => setMentionedAtURL(e.target.value)}
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {ensureProtocol(companyNode?.uniqueDomain) + '/'}
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  sx={validateCustomerStyles.addIndustryTabAddButton}
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={!sicCode}
                >
                  {t('validation.validateCustomers.addButtonLabel')}
                </Button>
              </FormControl>
            </Stack>
            <Box mt={2}>
              <Typography variant="body2">
                {t('validation.validateCustomers.horizontalSolutionInfoText')}
              </Typography>
              <Button
                sx={validateCustomerStyles.addIndustryTabHorizontalSolutionButton}
                variant="contained"
                onClick={handleMarkProductAsHorizontal}
              >
                {t('validation.validateCustomers.horizontalSolutionButtonLabel')}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Box mt={2}>
        <SicCodeOpenAi onSicCodeChange={handleSetOpenAISicCode} />
      </Box>
      <CustomBackdrop
        open={
          markCustomerReferenceObsoleteLoading ||
          deleteCustomerReferenceLoading ||
          addIndustryLoading ||
          markAsHorizontalLoading
        }
      />
    </Box>
  );
};

export default ValidateCustomers;
