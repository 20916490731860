import Box from '@mui/material/Box';

import { ChatbotAssistant } from '../../components/Chatbot/ChatbotAssistant';

export default function Home() {
  return (
    <Box height="90vh">
      <ChatbotAssistant />
    </Box>
  );
}
