import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Box, Chip, IconButton, Rating, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import CustomBackdrop from '../../components/CustomBackdrop/CustomBackdrop';
import {
  BuyAndBuildListDocument,
  useBuyAndBuildListLazyQuery,
  useLabelBuyAndBuildTargetQualityMutation,
  useRemoveShortlistedBuyAndBuildTargetsMutation,
  useShortlistBuyAndBuildTargetMutation,
} from '../../gql/generated/graphql';
import { handleVisitPage } from '../../helpers/helpers';
import usePagination from '../../hooks/usePagination';
import CustomPaper from '../DFMPage/components/CustomPaper/CustomPaper';

import { useTargetRating } from './hooks/useTargetRating';
import { MUI_RATING_SCALE } from './BuyBuildPage';

import styles from './styles';

type Props = {
  companyId: string | null;
  markCompanyAsNotSoftwareLoading: boolean;
  handleValidate: (companyId: number) => void;
  handleNavigateToAssesment: (companyId: number) => void;
  handleMarkAsNotSoftware: (id: number) => void;
};

export const BBLonglist = ({
  companyId,
  markCompanyAsNotSoftwareLoading,
  handleValidate,
  handleMarkAsNotSoftware,
  handleNavigateToAssesment,
}: Props) => {
  const { t } = useTranslation();
  const [
    getBuyAndBuild,
    { data: buyAndBuild, loading: buyAndBuildListLoading, fetchMore, refetch },
  ] = useBuyAndBuildListLazyQuery();

  const { handleRateTarget, rateBuyAndBuildLoading } = useTargetRating(companyId);

  const [addToShortlist, { loading: addToShortlistLoading }] =
    useShortlistBuyAndBuildTargetMutation();
  const [removeFromShortlist, { loading: removeFromShortlistLoading }] =
    useRemoveShortlistedBuyAndBuildTargetsMutation();

  useEffect(() => {
    if (!companyId) return;

    getBuyAndBuild({
      variables: { companyId: parseInt(companyId), first: 10 },
    });
  }, [companyId]);

  const buyAndBuildPageInfo =
    buyAndBuild?.companyAssesment?.edges?.[0]?.node?.buyAndBuildTargets?.pageInfo;
  const buyAndBuildData =
    buyAndBuild?.companyAssesment?.edges?.[0]?.node?.buyAndBuildTargets?.edges?.map(
      (node) => node?.node,
    );
  const buyAndBuildDataFormatted = buyAndBuildData?.map((node) => ({
    ...node.company,
    buyAndBuildTargetRelevance: node.buyAndBuildRelevance,
    isShortlisted: node.isShortlistedCompany1,
    manuallyLabeledScore: node.manuallyLabeledScore,
  }));

  const { paginationModel, isLoading, handlePaginationChange } = usePagination({
    fetchMore,
    pageInfo: buyAndBuildPageInfo,
  });

  const longlistColumns = [
    {
      field: 'uniqueDomain',
      headerName: t('buyAndBuild.urlColumnField'),
      width: 200,
    },
    {
      field: 'country',
      headerName: t('buyAndBuild.countryColumnField'),
      width: 100,
    },
    {
      field: 'fte',
      headerName: t('buyAndBuild.fteColumnField'),
      width: 100,
    },
    {
      field: 'iabDomainCategory',
      headerName: t('buyAndBuild.productColumnField'),
      width: 200,
    },
    {
      field: 'latestCrawlResult',
      headerName: t('buyAndBuild.keywordsColumnField'),
      width: 200,
      valueGetter: (params) => {
        const keywords = params?.xoviKeywords?.edges?.slice(0, 2).map((node) => node?.node);
        return keywords;
      },
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            height: '100%',
            gap: 1,
          }}
        >
          {params.formattedValue?.map((node, index) => (
            <Chip key={node?.keyword + node?.cpc + index} label={node?.keyword} size="small" />
          ))}
        </Box>
      ),
    },
    {
      field: 'summaryDescription',
      headerName: t('buyAndBuild.summaryColumnField'),
      width: 300,
      renderCell: (params) => (
        <Box display="flex" sx={{ alignItems: 'center', height: '100%' }}>
          <Typography
            variant="body2"
            sx={{
              textWrap: 'wrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              ellipsizeMode: 'tail',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
            }}
          >
            {params?.formattedValue}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      width: 300,
      renderCell: (params) => {
        const isShortlisted = params?.row?.isShortlistedCompany1;
        const ratingValue = params?.row?.manuallyLabeledScore
          ? params?.row?.manuallyLabeledScore
          : params?.row?.buyAndBuildTargetRelevance;
        return (
          <Box display="flex" height="100%">
            <IconButton size="small" onClick={() => handleVisitPage(params?.row?.uniqueDomain)}>
              <Tooltip title={t('buyAndBuild.visitButtonTooltip')}>
                <LanguageIcon fontSize="small" />
              </Tooltip>
            </IconButton>
            <IconButton size="small" onClick={() => handleValidate(params.row?.dbId)}>
              <Tooltip title={t('buyAndBuild.validateButtonTooltip')}>
                <AssignmentTurnedInOutlinedIcon fontSize="small" />
              </Tooltip>
            </IconButton>
            <IconButton size="small" onClick={() => handleNavigateToAssesment(params.row?.dbId)}>
              <Tooltip title={t('buyAndBuild.evaluateButtonTooltip')}>
                <FactCheckOutlinedIcon fontSize="small" />
              </Tooltip>
            </IconButton>
            <IconButton size="small" onClick={() => handleMarkAsNotSoftware(params.row?.dbId)}>
              <Tooltip title={t('buyAndBuild.markAsNotSoftwareButton')}>
                <AssignmentTurnedInOutlinedIcon fontSize="small" />
              </Tooltip>
            </IconButton>
            <IconButton size="small" onClick={() => handleAction(params.row)}>
              <Tooltip
                title={
                  isShortlisted
                    ? t('buyAndBuild.removeFromShortListColumnTooltip')
                    : t('buyAndBuild.addToShortlistColumnTooltip')
                }
              >
                {isShortlisted ? (
                  <StarIcon fontSize="small" />
                ) : (
                  <StarOutlineIcon fontSize="small" />
                )}
              </Tooltip>
            </IconButton>
            <Tooltip title={t('buyAndBuild.rateTarget')}>
              <Rating
                name="rating"
                sx={styles.rating}
                value={ratingValue * MUI_RATING_SCALE}
                precision={0.1}
                onChange={async (_, newValue) => {
                  await handleRateTarget(params.row.dbId, newValue);
                }}
              />
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const handleAction = async (row) => {
    const variables = {
      softwareCompanyId: buyAndBuild?.companyAssesment?.edges?.[0]?.node?.dbId,
      targetCompanyId: row?.dbId,
    };

    if (row?.isShortlistedCompany1) {
      const result = await removeFromShortlist({
        variables,
      });
      if (result?.data?.removeShortlistedBuyAndBuildTargets?.ok) {
        await refetch();
      }
    } else if (!row?.isShortlistedCompany1) {
      const result = await addToShortlist({
        variables,
      });
      if (result?.data?.shortlistBuyAndBuildTarget?.ok) {
        await refetch();
      }
    }
  };

  const showBackdrop =
    addToShortlistLoading ||
    removeFromShortlistLoading ||
    markCompanyAsNotSoftwareLoading ||
    rateBuyAndBuildLoading;

  return (
    <Box sx={styles.box}>
      <CustomPaper>
        <DataGrid
          paginationMode="server"
          loading={buyAndBuildListLoading || isLoading}
          disableRowSelectionOnClick
          autoHeight
          rows={buyAndBuildDataFormatted || []}
          getRowId={(row) => row?.dbId}
          columns={longlistColumns}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
          rowHeight={75}
          initialState={{
            pagination: {
              rowCount: -1,
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          // slots={{ toolbar: GridToolbar }}
          pageSizeOptions={[10]}
        />
      </CustomPaper>
      <CustomBackdrop open={showBackdrop} />
    </Box>
  );
};
