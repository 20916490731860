import {
  BuyAndBuildListDocument,
  useLabelBuyAndBuildTargetQualityMutation,
} from '../../../gql/generated/graphql';
import { MUI_RATING_SCALE } from '../BuyBuildPage';

export const useTargetRating = (companyId: string) => {
  const [rateBuyAndBuild, { loading: rateBuyAndBuildLoading }] =
    useLabelBuyAndBuildTargetQualityMutation();

  const handleRateTarget = async (targetCompanyId: number, rating: number) => {
    const quality = rating / MUI_RATING_SCALE;

    await rateBuyAndBuild({
      variables: {
        quality,
        softwareCompanyId: parseInt(companyId),
        targetCompanyId,
      },
      refetchQueries: [
        {
          query: BuyAndBuildListDocument,
          variables: {
            companyId: parseInt(companyId),
            first: 10,
          },
        },
      ],
    });
  };

  return {
    handleRateTarget,
    rateBuyAndBuildLoading,
  };
};
