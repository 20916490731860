import { useTranslation } from 'react-i18next';

import { Box, Button, TextField, Typography } from '@mui/material';

import TabPanel from './TabPanel';

type Props = {
  activeTab: number;
  totalTabs: number;
  newSegmentData: {
    name: string;
    description: string;
    keyword1: string;
  };
  handleNewSegmentChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSave: () => void;
};

const AddNewSegment = ({
  activeTab,
  totalTabs,
  newSegmentData,
  handleNewSegmentChange,
  handleSave,
}: Props) => {
  const { t } = useTranslation();
  const disabledSave =
    !newSegmentData.name || !newSegmentData.description || !newSegmentData.keyword1;
  return (
    <TabPanel value={activeTab} index={totalTabs - 1}>
      <Typography variant="h6" mb={3}>
        {t('customerSegmentPage.addNewSegment')}
      </Typography>
      <Box display="flex" flexDirection="column" gap={2} component="form">
        <TextField
          label="Segment Name"
          name="name"
          value={newSegmentData.name}
          onChange={handleNewSegmentChange}
          required
          fullWidth
          size="small"
        />
        <TextField
          label="Description"
          name="description"
          value={newSegmentData.description}
          onChange={handleNewSegmentChange}
          multiline
          required
          rows={4}
          fullWidth
          size="small"
        />
        <TextField
          label="Keyword"
          name="keyword1"
          value={newSegmentData.keyword1}
          onChange={handleNewSegmentChange}
          fullWidth
          required
          size="small"
        />
        <Box>
          <Button variant="contained" onClick={handleSave} disabled={disabledSave} sx={{ mt: 2 }}>
            {t('customerSegmentPage.saveNewSegmentButton')}
          </Button>
        </Box>
      </Box>
    </TabPanel>
  );
};

export default AddNewSegment;
