import { LangChainMessage } from '@assistant-ui/react-langgraph';
import { Client, ThreadState } from '@langchain/langgraph-sdk';

const createClient = () => {
  const apiUrl = process.env.REACT_APP_LANGGRAPH_API_URL || '/api';
  console.log('URL: ' + apiUrl);
  return new Client({
    apiUrl,
    apiKey: process.env.REACT_APP_LANGCHAIN_API_KEY,
  });
};

export const createThread = async () => {
  const client = createClient();
  return client.threads.create();
};

export const getThreadState = async (
  threadId: string,
): Promise<ThreadState<{ messages: LangChainMessage[] }>> => {
  const client = createClient();
  return client.threads.getState(threadId);
};

export const sendMessage = async (params: { threadId: string; messages: LangChainMessage[] }) => {
  const client = createClient();
  console.log('Sending message: ' + params.messages);
  return client.runs.stream(params.threadId, process.env.REACT_APP_LANGGRAPH_ASSISTANT_ID!, {
    input: {
      messages: params.messages,
    },
    streamMode: 'messages',
  });
};
