import { RouterProvider } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';
import { Authenticator } from '@aws-amplify/ui-react';
import CssBaseline from '@mui/material/CssBaseline';
import { registerLicense } from '@syncfusion/ej2-base';
import { Amplify } from 'aws-amplify';

import PendoScriptInjector from './components/PendoScriptInjector';
import { NotificationsProvider } from './contexts/NotificationsContext';
import { apolloClient } from './gql/clients';
import { awsExports } from './lib/aws-exports';
import { ChatbotAssistantRuntimeProvider } from './providers/ChatbotAssistantRuntimeProvider';
import { SnackbarProvider } from './providers/SnackbarProvider';
import Notifications from './services/Notifications';
import ThemeProvider from './theme/ThemeProvider';
import router from './routes';

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);

Amplify.configure(awsExports);

export function App() {
  return (
    <>
      <Authenticator.Provider>
        <PendoScriptInjector />
        <ThemeProvider>
          <ChatbotAssistantRuntimeProvider>
            <SnackbarProvider>
              <ApolloProvider client={apolloClient}>
                <CssBaseline />
                <NotificationsProvider>
                  <Notifications />
                  <RouterProvider router={router} />
                </NotificationsProvider>
              </ApolloProvider>
            </SnackbarProvider>
          </ChatbotAssistantRuntimeProvider>
        </ThemeProvider>
      </Authenticator.Provider>
    </>
  );
}

export default App;
