export const ensureProtocol = (url: string) => {
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};

export const openCorrectedLink = (url: string) => {
  url = url.replace(/^(https:\/\/)+/, 'https://').replace(/(https:\/\/)(?=.*https:\/\/)/g, '');
  const matches = url.match(/https:\/\/[^ ]+/g);
  if (matches?.length) window.open(matches[matches.length - 1], '_blank');
};

export const extractKeywords = (data) => {
  if (!data) return [];
  return data?.getCompany?.edges?.[0]?.node?.crawlResults?.edges
    ?.flatMap((edge) => edge?.node?.xoviKeywords)
    ?.flatMap((edge) => edge?.edges)
    ?.map((node) => ({ id: node?.node?.id, keyword: node?.node?.keyword }));
};

export const handleVisitPage = (webUrl: string) => {
  const url = ensureProtocol(webUrl);
  window.open(url, '_blank');
};

export const toSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
