import { SxProps, Theme } from '@mui/system';

const styles: Record<string, SxProps<Theme>> = {
  inputTitle: {
    fontWeight: 'bold',
    minWidth: '100px',
  },
  button: {
    alignSelf: 'flex-start',
    mt: 2,
  },
};

export default styles;
