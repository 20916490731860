import { useState } from 'react';

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { GenericShareHolderType } from '@/gql/generated/graphql';

type Props = {
  items: Partial<GenericShareHolderType>[];
  id: number | null;
  onSelect: (dbId: number) => void;
};

const ShaderholdersPicker = ({ items, id, onSelect }: Props) => {
  const handleChange = (event: SelectChangeEvent<number | null>) => {
    const dbId = event.target.value === '' ? null : Number(event.target.value);
    if (dbId !== null) {
      onSelect(dbId);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel size="small" id="shareholder-dropdown">
        Select Shareholder
      </InputLabel>
      <Select
        size="small"
        labelId="shareholder-item"
        value={id !== null ? id : ''}
        onChange={handleChange}
        label="Select Shareholder"
      >
        {items.map((item) => (
          <MenuItem key={item.dbId} value={item.dbId}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ShaderholdersPicker;
