import { useTranslation } from 'react-i18next';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';

import { CustomerSegmentsQuery } from '../../../gql/generated/graphql';

import Segment from './Segment';
import TabPanel from './TabPanel';

type Props = {
  segments: CustomerSegmentsQuery['customerSegments'];
  activeTab: number;
  companyId: string;
  onDeleteSegment: (id: number) => void;
};

const TabSegmentList = ({ segments = [], activeTab, companyId, onDeleteSegment }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {segments?.map((segment, index) => (
        <TabPanel key={segment.id} value={activeTab} index={index + 1}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">{segment.segmentName}</Typography>
            <Tooltip title={t('customerSegmentPage.deleteSegmentTooltip')}>
              <IconButton onClick={() => onDeleteSegment(segment.id)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Segment segment={segment} companyId={companyId} />
        </TabPanel>
      ))}
    </>
  );
};

export default TabSegmentList;
