import { SxProps, Theme } from '@mui/system';

const styles: Record<string, SxProps<Theme>> = {
  container: {
    borderBottom: 1,
    borderColor: 'divider',
    position: 'relative',
    '& .MuiTabs-root': {
      minHeight: '48px',
    },
  },
  tabs: {
    '& .MuiTabs-scrollButtons': {
      '&.Mui-disabled': {
        opacity: 0.3,
      },
    },
  },
};

export default styles;
